export const JOININ_TRANSLATE = {
    joinin: {
        messages: {
            inactive: "Joinin inativado com sucesso",
            delete: "Joinin inativado com sucesso",
            update: "Joinin removido com sucesso",
            recover: "Joinin ativado com sucesso",
            create: "Joinin criado com sucesso"

        },
        list: {
            confirmInactivateDialog: {
                title: "Inativar joinin?",
                body: "Tem certeza que deseja inativar o joinin {{version}}?",
                actions: {
                    inactivate: "Sim, inativar",
                    back: "Voltar",
                },
            },
            confirmDeleteDialog: {
                title: "Atenção!",
                body: "Deseja remover o joinin?",
            },
            table: {
                columns: {
                    // id: "ID",
                    androidVersion: "Código da versão",
                    applicationType: "Tipo de dispositivo",
                    updateType: "Atualização",
                    productType: "Produto"
                },
            },
            listActions: {
                inactivate: {
                    text: "Inativar",
                },
                activate: {
                    text: "Ativar",
                },
            },
            filters: {
                fields: {
                    baseUrl: {
                        label: "URL da API",
                        placeholder: "Informe aqui a URL da API"
                    },
                    baseUrlWeb: {
                        label: "URL da web",
                        placeholder: "Informe aqui a URL da web"
                    },
                    baseUrlWs: {
                        label: "URL do WS",
                        placeholder: "Informe aqui a URL do WS"
                    },
                    passwordRecoverUrl: {
                        label: "URL de recuperação de senha",
                        placeholder: "Informe aqui a URL de recuperação de senha"
                    },
                },
            },
        },
        create: {
            form: {
                actions: {
                    save: {
                        text: "Salvar"
                    }
                }
            }
        },
        update: {
            form: {
                actions: {
                    save: {
                        text: "Salvar"
                    }
                }
            }
        },
        read: {
            tabs: {
                data: "Dados",
                // users: "Usuários"
            }
        },
        save: {
            form: {
                titles:{
                    environmentTest: "Ambiente de teste",
                    web: "WEB",
                    app: "APP",
                    imagesProductionTest: "Imagens para ambiente produção é teste"
                },
                fields: {
                    baseUrl: {
                        label: "URL da API",
                        placeholder: "Informe aqui a URL da API"
                    },
                    projectName: {
                        label: "Nome do projeto",
                        placeholder: "Informe aqui o nome do projeto"
                    },
                    baseUrlWeb: {
                        label: "URL da web",
                        placeholder: "Informe aqui a URL da web"
                    },
                    baseUrlWs: {
                        label: "URL do WS",
                        placeholder: "Informe aqui a URL do WS"
                    },
                    passwordRecoverUrl: {
                        label: "URL de recuperação de senha",
                        placeholder: "Informe aqui a URL de recuperação de senha"
                    },
                    contractTotalUsers: {
                        label: "Nº de usuários contratados",
                        placeholder: "Informe aqui o número de usuários contratados"
                    },
                    startProductionIn: {
                        label: "Início em produção em",
                        placeholder: "Insira aqui data",
                    },
                    applicationIcon: {
                        label: "Ícone da aplicação",
                        placeholder: "Informe aqui o nome do ícone para a aplicação",
                    },
                    logo: {
                        label: "Imagem no login no APP",
                        remove: "Remover",
                        add: "Adicionar imagem",
                        change: "Alterar imagem do login",
                        alternative: "Imagem do login no APP adicionada",
                        notFound: "Nenhuma imagem foi adicionada ainda",
                    },
                    image: {
                        label: "Imagem do login na web",
                        remove: "Remover",
                        add: "Adicionar imagem",
                        change: "Alterar imagem do login",
                        alternative: "Imagem do login na web adicionada",
                        notFound: "Nenhuma imagem foi adicionada ainda"
                    },
                    android: {
                        label: "Splash Screen Android",
                        remove: "Remover",
                        add: "Adicionar splash screen",
                        change: "Alterar splash screen",
                        alternative: "Splash screen adicionada",
                        notFound: "Nenhum splash screen foi adicionado ainda"
                    },
                    ios: {
                        label: "Splash Screen IOS",
                        remove: "Remover",
                        add: "Adicionar splash screen",
                        change: "Alterar splash screen",
                        alternative: "Splash screen adicionada",
                        notFound: "Nenhum splash screen foi adicionado ainda"
                    },
                    joininToolbarBackground: {
                        label: "Fundo da barra superior",
                        remove: "Remover",
                        add: "Adicionar fundo da barra superior",
                        change: "Alterar fundo da barra superior",
                        alternative: "Fundo da barra superior adicionado",
                        notFound: "Nenhum fundo da barra superior foi adicionado ainda"
                    },
                    baseTestUrlWeb: {
                        label: "URL teste da web",
                        placeholder: "Informe aqui a URL teste da web"
                    },
                    baseTestUrlWs: {
                        label: "URL teste do WS",
                        placeholder: "Informe aqui a URL teste do WS"
                    },
                    baseTestUrl: {
                        label: "URL teste da API",
                        placeholder: "Informe aqui a URL teste da API"
                    },

                },
                actions: {
                    save: {
                        text: "Salvar"
                    },
                    openLink: {
                        text: "Abrir url em nova aba"
                    }
                },
            },
        }
    },
};


