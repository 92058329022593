import {Authentication} from "../app/core/authentication/authentication";
import {env} from "../../env";

export const environment = {
    production: true,
    sandbox: false,
    demonstration: false,
    development: false,
    api: env.api,
    accountApi: 'https://accounts.velow.com.br',
    headers: Authentication.getHeaders,
    local: 'production'
};
