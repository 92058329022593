import {Injectable} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";

import {Translate, Auxiliary, SnackBarService, FormService} from "material-angular-components";

import {AuthenticationService} from "../authentication/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class ErrorsService {
    constructor(
        private authenticationService: AuthenticationService,
        private snackBarService: SnackBarService,
        private router: Router
    ) {
    }

    setErrorsByResponse(response: HttpErrorResponse): void {
        const responseError = response?.error;
        const errors = responseError?.errors;
        const baseError: string = errors?.base || responseError?.base || responseError?.error?.base;
        const baseErrorInList: string = baseError?.[0];
        const firstErrorsInList: string = errors?.[0];
        const message =
            responseError?.message ||
            (Auxiliary.isString(baseError) && baseError) ||
            (Auxiliary.isString(baseErrorInList) && baseErrorInList) ||
            (Auxiliary.isString(firstErrorsInList) && firstErrorsInList) ||
            (!Auxiliary.isInternalServerError(response) && Auxiliary.isString(responseError) && responseError) ||
            (Auxiliary.isInternalServerError(response) && 'snackBar.messages.internalServerError') ||
            '';

        if (!Auxiliary.isHTML(message)) this.snackBarService.create(message, false);

        FormService.sendErrorsToForm(errors);

        if (Auxiliary.isInternalServerError(response)) this.router.navigateByUrl("..");
        if (Auxiliary.redirectToLogin(response.status)) this.authenticationService.resetAuthentication();
        if (Auxiliary.noAccess(response.status)) this.router.navigateByUrl(Translate.value('routes.noAccess.path'));
        if (Auxiliary.isApiNotFound(response)) {
            this.snackBarService.create(Translate.value('errors.apiIsNotFound'), false);
            this.authenticationService.resetAuthentication();
        }
    }
}
