import {Generic} from "material-angular-components";
import {ROUTES_TRANSLATE} from "./routes-translate";
import {ACTIONS_TRANSLATE} from "./generic/actions";
import {GENERIC_ROUTES_TRANSLATE} from "./generic/routes";
import {ANDROID_STICKS_TRANSLATE} from "./android-sticks-translate";
import {MOBILE_VERSIONS_TRANSLATE} from "./mobile-versions-translate";
import {USERS_TRANSLATE} from "./users-translate";
import {CUSTOMER_PORTAL_TRANSLATE} from "./customer-portal-translate";
import {JOININ_TRANSLATE} from "./joinin-translate";
import {NICKNAME_TRANSLATE} from "./nicknames-translate";
import { MY_PROFILE } from "./my-profile-translate";
import { PASSWORD_RESET_TRANSLATE } from "./password-reset-translate";
import { REP_P_ACCESSES_TRANSLATE } from "./rep-p-accesses-translate";
import { CORPORATE_TV_VERSIONS_TRANSLATE } from "./corporate-tv-versions-translate";
import { FILE_PICKER_TRANSLATE } from "./file-picker-translate";
import {HEADQUARTERS_TRANSLATE} from "./headquarters-translate";

export const TRANSLATIONS: Generic = {
    "pt-BR": {


        ...ROUTES_TRANSLATE,
        ...ANDROID_STICKS_TRANSLATE,
        ...MOBILE_VERSIONS_TRANSLATE,
        ...USERS_TRANSLATE,
        ...CUSTOMER_PORTAL_TRANSLATE,
        ...JOININ_TRANSLATE,
        ...NICKNAME_TRANSLATE,
        ...MY_PROFILE,
        ...PASSWORD_RESET_TRANSLATE,
        ...REP_P_ACCESSES_TRANSLATE,
        ...CORPORATE_TV_VERSIONS_TRANSLATE,
        ...FILE_PICKER_TRANSLATE,
        ...HEADQUARTERS_TRANSLATE,


        generic: {
            ...ACTIONS_TRANSLATE,
            ...GENERIC_ROUTES_TRANSLATE,
            noResults: {
                label: "Nenhuma informação foi adicionada",
                description: "Quando forem adicionadas, aparecerão aqui.",
                filter: "Nada foi encontrado para a sua pesquisa",
                message: "Altere seus filtros e tente novamente",
            },
        },

        root: {
            language: "pt-BR",
            noscript: "Para visualizar essa aplicação, habilite o JavaScript, por favor.",
            title: "Auth",
            metas: {
                description: ``,
                keywords: ``,
                author: "Auth",
                copyright: "Auth"
            },
            workWithUs: {
                first: "",
                second: "",
                third: ""
            }
        },
        footer: {
            title: ""
        },
        developedBy: {
            title: "Auth"
        },
        globalLoading: {
            message: "Carregando o Auth",
            login: "Entrando no Auth",
            logout: "Saindo do Auth",
            switchUser: "Trocando de usuário",
            joinWithOtherUser: "Entrando com outro usuário",
            authentication: "Autenticando o usuário",
        },
        situations: {
            active: "Ativos",
            activeSingular: "Ativo",
            inactive: "Inativos",
            inactiveSingular: "Inativo",
            all: "Todos",
            allFemale: "Todas"
        },
        booleans: {
            yes: "Sim",
            no: "Não"
        },
        date: {
            dateAndHourSeparator: 'às',
            dateToDate: 'até'
        },
        errors: {
            moduleImport: "Ocorreu um erro ao tentar carregar essa página",
            connectionDown: "Você perdeu o acesso à rede",
            connectionComeBack: "Sua conexão foi restabelecida",
            apiIsNotFound: "A conexão com o servidor foi perdida, entre em contato com o suporte"
        },
        tabs: {
            urlName: "aba"
        },
        notFound: {
            title: "Página não encontrada",
            description: `
                Você tentou acessar uma página que não existe. Clique no botão abaixo para retornar ao início.
            `,
            actions: {
                back: {
                    text: "Voltar"
                }
            }
        },
        snackBar: {
            messages: {
                success: "Sucesso",
                error: "Erro",
                internalServerError: "Ocorreu um erro de conexão com o servidor"
            },
            actions: {
                close: {
                    text: "Fechar"
                },
                restore: {
                    text: "Desfazer"
                }
            }
        },
        dialog: {
            ariaLabel: "Caixa de diálogo",
            actions: {
                close: "Fechar",
                agree: "Concordo"
            }
        },
        localActions: {
            filters: {
                text: "Filtros",
                tooltip: "Filtros",
                badge: "Número de filtros ativos"
            },
            generateReport: {
                text: "Gerar relatório",
                tooltip: "Gerar relatório"
            },
        },
        listActions: {
            edit: {
                text: "Editar"
            },
            delete: {
                text: "Excluir"
            },
            inactive: {
                text: "Inativar"
            },
            active: {
                text: "Ativar"
            },
            recover: {
                text: "Restaurar"
            },
            remove: {
                text: "Remover"
            }
        },
        filters: {
            title: "Filtros",
            actions: {
                close: {
                    text: "Fechar"
                },
                clear: {
                    text: "Redefinir"
                },
                save: {
                    text: "Buscar"
                }
            },
            fields: {
                active: {
                    label: "Situação",
                    placeholder: "Escolha a situação",
                    url: "situacao",
                }
            }
        },
        unauthorizedPage: {
            redirect: {
                accounts: "Contas",
                home: "Início",
            }
        },
        form: {
            updateSubTitle: "Informe os campos corretamente e altere seu registro.",
            createSubTitle: "Informe os campos corretamente e crie seu registro.",
            select: {
                lastSeparator: " e",
                nothing: "Nenhum"
            },
            dateRange: {
                initPlaceholder: "Data inicial",
                finalPlaceholder: "Data final"
            },
            editor: {
                link: {
                    label: "Link",
                    placeholder: "Informe o link",
                    save: 'Salvar',
                    edit: 'Editar',
                    remove: 'Remover',
                    visitUrl: 'Visitar link'
                }
            },
            input: {
                password: {
                    show: "Exibir senha",
                    hide: "Esconder senha"
                }
            },
            chips: {
                remove: "Excluir item",
                notFound: "Itens não encontrados"
            },
            autocomplete: {
                notFound: "Itens não encontrados"
            },
            errors: {
                minlength: "Mínimo de {{ minlength }} caracteres",
                maxlength: "Máximo de {{ maxlength }} caracteres",
                min: "Valor mínimo permitido é {{ min }}",
                max: "Valor máximo permitido é {{ max }}",
                email: "E-mail é inválido",
                required: "Campo é obrigatório",
                matDatepickerParse: "Data é inválida",
                matStartDateInvalid: "Data é inválida",
                matEndDateInvalid: "Data é inválida",
                matDatepickerFilter: "Data é inválida",
                dateRangeMessageSeparated: "Data é inválida",
                url: "URL é inválida",
                color: "Cor é inválida",
                cnpj: "CNPJ é inválido",
                cpfOrEmail: "E-mail ou CPF é inválido",
                cep: "CEP é inválido",
                cpf: "CPF é inválido",
                hourMinute: "Hora é inválida",
                phone: "Telefone é inválido",
                cnh: "CNH é inválida",
                licensePlate: "Placa é inválida",
            },
            actions: {
                clear: {
                    text: "Limpar formulário"
                },
                save: {
                    text: "Salvar"
                },
                close: {
                    text: "Fechar"
                }
            }
        },
        table: {
            expanded: {
                column: "Coluna expansível",
                row: "Linha expansível"
            },
            noResults: {
                text: "Resultados não encontrados"
            },
            paginator: {
                firstPageLabel: "Primeira página",
                lastPageLabel: "Última página",
                itemsPerPageLabel: "Itens por página",
                nextPageLabel: "Próxima página",
                previousPageLabel: "Página anterior",
                rangeLabel: "{{ index }} até {{ limit }} de {{ length }}",
                perPageUrl: "por-pagina",
                pageUrl: "pagina"
            },
            columns: {
                actions: "",
                selection: {
                    all: {
                        select: "Selecionar todas as linhas",
                        deselect: "Desselecionar todas as linhas"
                    },
                    row: {
                        select: "Selecionar essa linha",
                        deselect: "Desselecionar essa linha"
                    }
                }
            }
        },
        list: {
            noResults: {
                label: "Não existem filtros cadastrados",
                description: "Cadastre um turno e ele aparecerá aqui",
                filter: "Turnos não encontrados",
                message: "Altere seus filtros e tente novamente"
            },
            moreResults: {
                text: "Mostrar mais"
            }
        },

        menu: {
            images: {
                headquarter: "Logo da unidade",
                account: "Logo da empresa"
            },
            notFound: {
                text: "Sem itens no menu"
            },
            loading: {
                text: "Carregando menu"
            },
            breadcrumb: {
                actions: {
                    menu: {
                        text: "Menu lateral"
                    }
                }
            },
            toolbar: {
                title: "Auth",
                adminTitle: "Administrador",
                actions: {
                    logout: {
                        text: "Sair"
                    },
                    myProfile: {
                        text: "Meu perfil"
                    }
                }
            },
            group: {
                accessModules: "Acesso",
                mobiles: "Mobiles",
                repP: "REP-P",
                corporateTv: "TV corporativa",
                users: "Usuários"
            },
            items: {
                customers: {
                    name: "Contas"
                },
                users: {
                    name: "Usuários"
                },
                mobileVersions: {
                    name: "Versões"
                },
                androidSticks: {
                    name: "Aparelhos"
                },
                nicknames: {
                    name: "Apelidos"
                },
                headquarters: {
                    name: "Unidades"
                },
                repPAccesses: {
                    name: "REP-P"
                },
                corporateTvVersions: {
                    name: "Versões"
                },
            },
        },

        home: {
            images: {
                angular: "Logo do Angular",
                portal: "Logo do Portal"
            }
        },
        signIn: {
            form: {
                title: "Entrar",
                fields: {
                    email: {
                        label: "Usuário",
                        placeholder: "Informe o usuário"
                    },
                    password: {
                        label: "Senha",
                        placeholder: "Informe a senha"
                    }
                },
                actions: {
                    save: {
                        text: "Entrar"
                    }
                }
            }
        },
        confirmDialog: {
            title: "Atenção",
            subTitle: "Deseja confirmar",
            action: "Sim",
            cancel: "Não"
        },
        passwordEdit: {
            messages: {
                error: "Ocorreu um erro ao alterar a senha"
            },
            back: "Voltar",
            form: {
                title: "Alterar senha",
                fields: {
                    password: {
                        label: "Nova senha",
                        placeholder: "Informe a nova senha",
                        hint: "A senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
                    },
                    passwordConfirmation: {
                        label: "Confirmação de nova senha",
                        placeholder: "Informe a confirmação de nova senha",
                        hint: "A confirmação de senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
                    }
                },
                actions: {
                    save: {
                        text: "Alterar"
                    }
                }
            }
        },
        accounts: {
            messages: {
                delete: "Conta inativada com sucesso",
                recover: "Conta restaurada com sucesso",
                update: "Conta atualizada com sucesso",
                create: "Conta criada com sucesso",
                images: "Identidade salva com sucesso",
            },
            list: {
                confirmInactivateDialog: {
                    title: "Inativar conta?",
                    body: 'Tem certeza que deseja inativar a conta "{{name}}"?',
                    actions: {
                        inactivate: "Sim, inativar",
                        back: "Voltar",
                    },
                },
                table: {
                    flags: {
                        active: "Ativo",
                        notActive: "Inativo",
                    },
                    columns: {
                        companyName: "Empresa",
                        cnpj: "CNPJ",
                        active: "Situação",
                        socialReason: "Razão social",
                        id: "ID",


                    }
                },
                localActions: {
                    create: {
                        text: "Criar conta"
                    }
                },
                filters: {
                    fields: {
                        companyName: {
                            label: "Empresa",
                            placeholder: "Informe o nome da empresa",
                            url: "companyName",
                        },
                        socialReason: {
                            label: "Razão social",
                            placeholder: "Informe a razão social",
                            url: "social-reason",
                        },
                        cnpj: {
                            label: "CNPJ",
                            placeholder: "Informe o cnpj",
                            url: "cnpj",
                        },
                        active: {
                            label: "Situação",
                            placeholder: "Informe a situação",
                            url: "situacao",
                        },
                        situation: {
                            label: "Situação",
                            placeholder: "Informe a situação",
                            url: "situation",
                        },
                    }
                }
            },
            create: {
                form: {
                    title: "Criar conta",
                    fields: {
                        name: {
                            label: "Nome",
                            placeholder: "Informe o nome"
                        },
                        uuid: {
                            label: "Identificador único",
                            placeholder: "Informe o UUID exato"
                        },
                    },
                    actions: {
                        save: {
                            text: "Criar conta"
                        }
                    }
                }
            },
            read: {
                tabs: {
                    data: "Dados gerais",
                    accountsJoinin: "Joinin",
                    customerPortal: "Portal do cliente",
                    users: "Joinin",
                    customers: "Apelidos",
                    connect: "Connect",
                    nicknames: "Apelidos"
                }
            },
            update: {
                form: {
                    title: "Editar conta",
                    fields: {
                        companyName: {
                            label: "Empresa",
                            placeholder: "Informe o nome da empresa"
                        },
                        socialReason: {
                            label: "Razão social",
                            placeholder: "Informe a razão social"
                        },
                        hasFirstAccess: {
                            label: "Primeiro acesso",
                            tooltip: "Ao selecionar essa opção, será exibido no login a funcionalidade primeiro acesso ao usuário"
                        },
                        cnpj: {
                            label: "CNPJ",
                            placeholder: "Informe o CNPJ"
                        },
                        uuid: {
                            label: "UUID",
                            placeholder: "Informe a uuid",
                        },
                        accountSituations: {
                            label: "Situação da conta",
                            placeholder: "Selecione a situação da conta"
                        },
                    },
                    actions: {
                        save: {
                            text: "Salvar"
                        },
                        copy: {
                            text: "Copiar"
                        }

                    }
                }
            },
            identity: {
                form: {
                    title: "Editar identidade",
                    fields: {
                        logo: {
                            label: "Logo",
                            remove: "Remover logo",
                            add: "Adicionar logo",
                            change: "Alterar logo",
                            alternative: "Logo adicionada pelo usuário",
                            notFound: "Nenhuma logo adicionada ainda"
                        }
                    },
                    actions: {
                        save: {
                            text: "Salvar"
                        }
                    }
                }
            },
            // users: {
            //     messages: {
            //         import: "Usuários importados com sucesso"
            //     },
            //     list: {
            //         listActions: {
            //             becomeUser: {
            //                 text: "Acessar como esse administrador"
            //             }
            //         },
            //         localActions: {
            //             importCsv: {
            //                 text: "Importar usuários"
            //             }
            //         },
            //         table: {
            //             columns: {
            //                 email: "E-mail",
            //                 name: "Nome",
            //                 cpf: "CPF",
            //
            //                 headquarter: "Unidade",
            //                 lastSignInAt: "Último acesso",
            //                 useTerms: "Termo de uso",
            //             },
            //             tooltips: {
            //                 accept: "Aceito"
            //             }
            //         },
            //         filters: {
            //             fields: {
            //                 name: {
            //                     label: "Nome",
            //                     placeholder: "Informe o nome",
            //                     url: "nome",
            //                 },
            //                 email: {
            //                     label: "E-mail",
            //                     placeholder: "Informe o e-mail",
            //                     url: "email",
            //                 }
            //             }
            //         }
            //     },
            //     importCsv: {
            //         form: {
            //             actions: {
            //                 save: {
            //                     text: "Salvar"
            //                 }
            //             },
            //             fields: {
            //                 file: {
            //                     label: "CSV de importação",
            //                     remove: "Remover CSV",
            //                     add: "Adicionar CSV",
            //                     change: "Alterar CSV",
            //                     alternative: "CSV importado pelo usuário",
            //                     notFound: "Nenhum CSV adicionado"
            //                 },
            //                 defaultTemplate: {
            //                     label: "Baixe o modelo"
            //                 }
            //             }
            //         },
            //         title: "Importar usuários"
            //     },
            // },
            customers: {
                messages: {
                    update: "Cliente atualizada com sucesso"
                },
                list: {
                    table: {
                        columns: {
                            name: "Nome",
                            codEmpErp: "Código do ERP da empresa",
                            codFilErp: "Código do ERP da unidade",
                            secondaryColor: "Cor secundária",
                            primaryColor: "Cor primária",
                            id: "#",
                            socialReason: "Razão social",
                            nickname: "Nome fantasia",
                            cnpj: "CNPJ",
                        }
                    },
                    localActions: {
                        create: {
                            text: 'Criar Cliente'
                        }
                    },
                    filters: {
                        fields: {
                            name: {
                                url: 'nome',
                                label: 'Nome',
                                placeholder: 'Informe o nome',
                            }
                        }
                    }
                },
                update: {
                    form: {
                        title: "Editar cliente",
                        fields: {
                            codEmpErp: {
                                label: "Código do ERP da empresa",
                                placeholder: "Informe o código do ERP da empresa",
                            },
                            codFilErp: {
                                label: "Código do ERP da unidade",
                                placeholder: "Informe o código do ERP da unidade",
                            },
                            primaryColor: {
                                label: "Cor primária",
                                placeholder: "Informe a cor primária",
                            },
                            secondaryColor: {
                                label: "Cor secundária",
                                placeholder: "Informe a cor secundária",
                            }
                        },
                        actions: {
                            save: {
                                text: "Salvar"
                            }
                        }
                    }
                }
            },
            integrations: {
                messages: {
                    delete: "Integração excluída com sucesso",
                    recover: "Integração restaurada com sucesso",
                    update: "Integração atualizada com sucesso",
                    create: "Integração adicionada com sucesso"
                },
                list: {
                    table: {
                        columns: {
                            description: "Descrição",
                            integrationType: "Tipo da integração",
                            remoteIp: "IP remoto",
                            token: "Token",
                        }
                    },
                    listActions: {
                        edit: {
                            text: "Editar integração"
                        }
                    },
                    localActions: {
                        create: {
                            text: "Adicionar integração"
                        }
                    },
                    filters: {
                        fields: {
                            description: {
                                label: "Descrição",
                                placeholder: "Informe a descrição",
                                url: "descricao"
                            },
                            remoteIp: {
                                label: "IP remoto",
                                placeholder: "Informe o IP remoto",
                                url: "ip-remoto"
                            },
                            integrationType: {
                                label: "Tipo da integração",
                                placeholder: "Informe o tipo da integração",
                                url: "tipo-da-integracao"
                            }
                        }
                    }
                },
                create: {
                    form: {
                        title: "Nova integração",
                        actions: {
                            save: {
                                text: "Adicionar"
                            }
                        }
                    }
                },
                update: {
                    form: {
                        title: "Editar integração",
                        actions: {
                            save: {
                                text: "Salvar"
                            }
                        }
                    }
                },
                save: {
                    form: {
                        fields: {
                            description: {
                                label: "Descrição",
                                placeholder: "Informe a descrição"
                            },
                            remoteIp: {
                                label: "IP remoto",
                                placeholder: "Informe o IP remoto"
                            },
                            token: {
                                label: "Token",
                                placeholder: "Informe o token"
                            },
                            integrationType: {
                                label: "Tipo da integração",
                                placeholder: "Informe o tipo da integração"
                            }
                        }
                    }
                },
                users: {
                    messages: {
                        delete: "Usuário excluído com sucesso",
                        recover: "Usuário restaurado com sucesso",
                        update: "Usuário atualizado com sucesso",
                        create: "Usuário criado com sucesso"
                    },
                    list: {
                        label: "Usuários",
                        table: {
                            columns: {
                                createdAt: "Criado em",
                                name: "Nome",
                                email: "E-mail",
                                lastSignInAt: "Data do último acesso"
                            }
                        },
                        listActions: {
                            edit: {
                                text: "Editar usuário"
                            }
                        },
                        actions: {
                            create: {
                                text: "Criar usuário"
                            }
                        },
                    },
                    create: {
                        form: {
                            title: "Novo usuário",
                            actions: {
                                save: {
                                    text: "Criar"
                                }
                            }
                        }
                    },
                    update: {
                        form: {
                            title: "Editar usuário",
                            actions: {
                                save: {
                                    text: "Salvar"
                                }
                            }
                        }
                    },
                    save: {
                        form: {
                            fields: {
                                name: {
                                    label: "Nome",
                                    placeholder: "Informe o nome"
                                },
                                email: {
                                    label: "E-mail",
                                    placeholder: "Informe o e-mail"
                                },
                                changePassword: {
                                    label: "Alterar senha"
                                },
                                password: {
                                    label: "Senha",
                                    placeholder: "Informe a senha",
                                    hint: "A senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
                                },
                                passwordConfirmation: {
                                    label: "Confirmação de senha",
                                    placeholder: "Informe a confirmação de senha",
                                    hint: "A confirmação de senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
                                }
                            }
                        }
                    }
                }
            },
            email: {
                form: {
                    receivingEmailTitle: "Editar recebimento de e-mail (exemplo: folhas de pagamento)",
                    smtpTitle: "Editar envio de e-mail (exemplo: recuperação de senha)",
                    hcmTitle: "Editar envio de e-mail dos relatórios HCM (exemplo: folhas de pagamento)",
                    fields: {
                        imapHost: {
                            label: "Host",
                            placeholder: "Informe o host",
                            hint: "Exemplo: https://portal.com.br"
                        },
                        imapSsl: {
                            label: "Habilitar SSL"
                        },
                        imapPassword: {
                            label: "Senha",
                            placeholder: "Informe a senha"
                        },
                        imapUser: {
                            label: "Usuário",
                            placeholder: "Informe o usuário"
                        },
                        imapPort: {
                            label: "Porta",
                            placeholder: "Informe a porta"
                        },
                        imapExecutionMaxTime: {
                            label: "Tempo máximo para recebimento do e-mail",
                            placeholder: "Informe o tempo máximo para recebimento do e-mail",
                            hint: "Expirar solicitação, informação em minutos"
                        },
                        imapExecutionIntervalTime: {
                            label: "Intervalo entre capturas de e-mail",
                            placeholder: "Informe o intervalo entre capturas de e-mail",
                            hint: "Colocar em minutos"
                        },
                        smtpEmail: {
                            label: "E-mail",
                            placeholder: "Informe o e-mail"
                        },
                        smtpUser: {
                            label: "Usuário",
                            placeholder: "Informe o usuário"
                        },
                        smtpPassword: {
                            label: "Senha",
                            placeholder: "Informe a senha"
                        },
                        smtpHost: {
                            label: "Host",
                            placeholder: "Informe o host",
                            hint: "Exemplo: https://portal.com.br"
                        },
                        reportsSenderEmail: {
                            label: "E-mail",
                            placeholder: "Informe o e-mail"
                        }
                    },
                    actions: {
                        save: {
                            text: "Salvar"
                        }
                    }
                }
            },
            activeDirectory: {
                form: {
                    title: "Editar Active Directory",
                    fields: {
                        isActiveDirectory: {
                            label: "Ativar"
                        },
                        activeDirectoryHost: {
                            label: "Host",
                            placeholder: "Informe o host"
                        },
                        activeDirectoryBase: {
                            label: "Base",
                            placeholder: "Informe a base"
                        },
                        activeDirectoryDomain: {
                            label: "Domínio",
                            placeholder: "Informe o domínio"
                        }
                    },
                    actions: {
                        save: {
                            text: "Salvar"
                        }
                    }
                }
            },
            advancedConfigurations: {
                form: {
                    title: "Editar configurações",
                    fields: {
                        usersTimeout: {
                            label: "Deseja fazer logout por inatividade"
                        },
                        timeoutIn: {
                            label: "Tempo para logout",
                            placeholder: "Informe o tempo para logout",
                            hint: "Informação em minutos",
                        },
                        layoutSpaceBar: {
                            label: "Estilo do espaço",
                            placeholder: "Informe o estilo do espaço"
                        }
                    },
                    actions: {
                        save: {
                            text: "Salvar"
                        }
                    }
                }
            },
        },
        customers: {
            messages: {
                delete: "Cliente inativado com sucesso",
                recover: "Cliente ativado com sucesso",
                update: "Cliente atualizado com sucesso",
                create: "Cliente criado com sucesso",
                updatePassword: "Senha alterada com sucesso",
                photoAdded: "Foto adicionada com sucesso",
                photoRemoved: "Foto removida com sucesso",
            },
            read: {
                tabs: {
                    data: "Dados gerais",
                    password: "Alterar senha",
                    others: "Outros"
                }
            },
            list: {
                table: {
                    columns: {
                        cnpj: "CNPJ",
                        email: "E-mail",
                        name: "Nome fantasia",
                        code: "Código do cliente",
                    },
                    tooltips: {
                        accept: "Aceito"
                    }
                },
                localActions: {
                    create: {
                        text: "Criar Cliente"
                    },
                },
                filters: {
                    fields: {
                        name: {
                            label: "Nome",
                            placeholder: "Informe o nome",
                            url: "nome",
                        },
                        cnpj: {
                            label: "CNPJ",
                            placeholder: "Informe o e-mail",
                            url: "email",
                        }
                    }
                }
            },
            save: {
                form: {
                    fields: {
                        cnpj: {
                            label: "CNPJ",
                            placeholder: "Informe o CNPJ"
                        },
                        name: {
                            label: "Nome fantasia",
                            placeholder: "Informe o nome fantasia"
                        },
                        email: {
                            label: "E-mail",
                            placeholder: "Informe o e-mail"
                        },
                        socialReason: {
                            label: "Razão social",
                            placeholder: "Informe a Razão social"
                        },
                        address: {
                            label: "Endereço",
                            placeholder: "Informe o endereço"
                        },
                        zipcode: {
                            label: "CEP",
                            placeholder: "Informe o CEP"
                        },
                        addressNumber: {
                            label: "Número",
                            placeholder: "Informe o número"
                        },
                        addressComplement: {
                            label: "Complemento",
                            placeholder: "Informe o complemento"
                        },
                        city: {
                            label: "Cidade",
                            placeholder: "Informe o cidade"
                        },
                        state: {
                            label: "Estado",
                            placeholder: "Informe o Estado"
                        },
                        phone: {
                            label: "Telefone",
                            placeholder: "Informe o telefone"
                        },
                        secondaryPhone: {
                            label: "Telefone secundário",
                            placeholder: "Informe o telefone secundário"
                        },
                        code: {
                            label: "Código do cliente",
                            placeholder: ""
                        }
                    },
                }
            },
            update: {
                form: {
                    title: "Editar Cliente",
                    actions: {
                        save: {
                            text: "Salvar"
                        }
                    }
                }
            },
            create: {
                form: {
                    title: "Criar cliente",
                    actions: {
                        save: {
                            text: "Criar"
                        }
                    }
                }
            },
        },
    }
};
