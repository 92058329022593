import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LocalActionsComponent} from './local-actions.component';
import {FormModule} from "material-angular-components";

@NgModule({
    declarations: [
        LocalActionsComponent
    ],
    imports: [
        CommonModule,
        FormModule,
    ],
    exports: [
        LocalActionsComponent
    ]
})
export class LocalActionsModule {
}
