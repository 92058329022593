import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {TranslateModule} from "@ngx-translate/core";
import {AngularMaterialModule, FormModule} from "material-angular-components";

import {FiltersComponent} from './filters.component';

@NgModule({
    declarations: [
        FiltersComponent
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        FormModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        FiltersComponent
    ]
})
export class FiltersModule {
}
