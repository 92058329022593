export const REP_P_ACCESSES_TRANSLATE = {
	repPAccesses: {
		messages: {
            inactive: "REP-P inativado com sucesso",
			delete: "REP-P inativado com sucesso",
			update: "REP-P atualizado com sucesso",
            recover: "REP-P ativado com sucesso",
            create: "REP-P criado com sucesso"

		},
		list: {
			confirmInactivateDialog: {
				title: "Inativar REP-P?",
				body: 'Tem certeza que deseja inativar o REP-P "{{name}}"?',
				actions: {
					inactivate: "Sim, inativar",
					back: "Voltar",
				},
			},
			confirmDeleteDialog: {
				title: "Atenção!",
				body: "Deseja remover REP-P?",
			},
			table: {
				columns: {
					id: "ID",
                    companyName: "Conta",
                    user: "Usuário"
				},
			},
			listActions: {
				inactivate: {
					text: "Inativar",
				},
				activate: {
					text: "Ativar",
				},
			},
			filters: {
				fields: {
                    companyName: {
						label: "Conta",
						placeholder: "Informe aqui a conta",
						url: "account",
					},
                    name: {
                        label: "Nome",
                        placeholder: "Informe aqui o nome",
                        url: "name",
                    },
                    user: {
                        label: "Usuário",
                        placeholder: "Informe aqui o usuário",
                        url: "user",
                    },
                    id: {
                        label: "ID",
                        placeholder: "Informe aqui o ID",
                        url: "id",
                    },
				},
			},
		},
	    create: {
			form: {
                fields: {
                    readMethodOptions: {
                        badge: "Badge",
                        registration: "Registro"
                    },
                    identificationMethodOptions: {
                        scan: "Câmera",
                        keyboard: "Teclado"
                    },
                    requiredFacialId: {
                        label: "Reconhecimento facial",
                        tooltip: "Caso você marque esta opção será requerido o reconhecimento facial"
                    },
                    uuid: {
                        label: "UUID",
                        placeholder: "Informe aqui o uuid"
                    },
                    companyName: {
                        label: "Conta",
                        placeholder: "Informe aqui a conta"
                    },
                    name: {
                        label: "Nome",
                        placeholder: "Informe aqui o nome"
                    },
                    user: {
                        label: "Usuário",
                        placeholder: "Informe aqui o usuário"
                    },
                    password: {
                        label: "Senha",
                        placeholder: "Informe aqui a senha"
                    },
                    companyCode: {
                        label: "Código empresa",
                        placeholder: "Informe aqui o código da empresa"
                    },
                    headquarterCode: {
                        label: "Código filial",
                        placeholder: "Infome o código da filial"
                    },
                },
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		update: {
			form: {
                fields: {
                    readMethodOptions: {
                        badge: "Badge",
                        registration: "Registro"
                    },
                    identificationMethodOptions: {
                        scan: "Câmera",
                        keyboard: "Teclado"
                    },
                    uuid: {
                        label: "UUID",
                        placeholder: "Informe aqui o uuid"
                    },
                    companyName: {
                        label: "Conta",
                        placeholder: "Informe aqui a conta"
                    },
                    name: {
                        label: "Nome",
                        placeholder: "Informe aqui o nome"
                    },
                    user: {
                        label: "Usuário",
                        placeholder: "Informe aqui o usuário"
                    },
                    password: {
                        label: "Senha",
                        placeholder: "Informe aqui a senha"
                    },
                    companyCode: {
                        label: "Código empresa",
                        placeholder: "Informe aqui o código da empresa"
                    },
                    headquarterCode: {
                        label: "Código da filial",
                        placeholder: "Infome o código da filial"
                    },
                },
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		read: {
			tabs: {
				data: "Dados",
				// users: "Usuários"
			}
		},
		save: {
			form: {
				fields: {
					customUuid: {
						label: "UUID",
						placeholder: "Informe aqui o uuid"
					},
					companyName: {
						label: "Conta",
						placeholder: "Informe aqui a conta"
					},
				},
			}
		},
	},

};


