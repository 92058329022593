import {HttpHeaders, HttpResponse} from "@angular/common/http";

import {Auxiliary, Generic} from "material-angular-components";

export class Authentication {
    static nameProperty = 'headers';
    static accessToken = 'access-token';
    static propertyParameters: Generic = {
        authkey: '70c49c9cce759ce5f915',
        uid: '',
        [Authentication.accessToken]: '',
        client: ''
    };
    static headers = new HttpHeaders(Authentication.propertyParameters);

    static getHeadersAsObject(): Generic {
        return Authentication.getCurrentHeadersObjectByKeys(Authentication.headers.keys());
    }

    static isThereAccessToken(): boolean {
        Authentication.getHeaders();

        return !!Authentication.headers.get(Authentication.accessToken);
    };

    static getCurrentHeadersObjectByKeys(keys: string[]): Generic {
        const headersObject: Generic = {};

        keys.forEach(key => headersObject[key] = Authentication.headers.get(key));

        return headersObject;
    };

    static getHeaders(): HttpHeaders {
        const headersFromLocalStorage = JSON.parse(
            localStorage?.getItem(Authentication.nameProperty)?.toString?.() || '{}'
        ) as Generic;

        Authentication.setHeaders(headersFromLocalStorage);

        return Authentication.headers;
    };

    static setHeaders(newHeaders: Generic = {}): HttpHeaders {
        const nextHeaders = Object.assign(Authentication.getHeadersAsObject(), newHeaders);

        for (const property in nextHeaders) {
            if (nextHeaders.hasOwnProperty(property)) {
                if (!Auxiliary.isUndefined(nextHeaders[property]) && !Auxiliary.isNull(nextHeaders[property])) {
                    Authentication.headers = Authentication.headers.set(property, nextHeaders[property]);
                } else {
                    Authentication.headers = Authentication.headers.delete(nextHeaders[property]);
                    delete nextHeaders[property];
                }
            }
        }

        localStorage.setItem(Authentication.nameProperty, JSON.stringify(nextHeaders));

        return Authentication.headers;
    };

    static createHeadersByResponse(response: HttpResponse<any>): Generic {
        const newHeaders: Generic = {};
        const propertyParametersKeys = Object.keys(Authentication.propertyParameters);

        propertyParametersKeys.forEach(item => {
            const value = response.headers.get(item);

            if (value) newHeaders[item] = value;
        });

        return newHeaders;
    };

    static resetHeaders(): void {
        Authentication.setHeaders({uid: '', [Authentication.accessToken]: '', client: ''});
    }
}
