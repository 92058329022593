import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MatSidenav} from "@angular/material/sidenav";

import {Observable, of, Subscription} from "rxjs";
import {distinctUntilChanged, filter} from "rxjs/operators";
import {Auxiliary, FormButton, Generic} from "material-angular-components";

import {LocalActionsService} from '../../local-actions/local-actions.service';
import {MenuService} from '../menu.service';
import {AppExtrasService} from "../../../app-extras.service";
import {MenuBreadcrumb} from "../../../shared/models/menu-breadcrumb";
import {MenuBreadcrumbService} from "./menu-breadcrumb.service";
import {AppRoutesService} from "../../../app-routes.service";
import {AppService} from "../../../app.service";
import {TabsService} from "../../../shared/components/tabs/tabs.service";

@Component({
    selector: 'acc-menu-breadcrumb',
    templateUrl: './menu-breadcrumb.component.html',
    styleUrls: ['./menu-breadcrumb.component.scss'],
    preserveWhitespaces: false
})
export class MenuBreadcrumbComponent implements OnInit, OnDestroy {
    @Input() menu: MatSidenav = {} as MatSidenav;

    createAction: FormButton | Generic = {};
    delay = 100;

    private subscriptions: Subscription[] = [];

    constructor(
        private actionsService: LocalActionsService,
        private menuService: MenuService,
        private changeDetector: ChangeDetectorRef,
        private rootExtrasService: AppExtrasService,
        public router: Router,
        private routesService: AppRoutesService,
        private route: ActivatedRoute,
        public service: MenuBreadcrumbService,
        public root: AppService,
        private tabsService: TabsService
    ) {
        this.service.breadcrumb = this.createBreadCrumb(this.route.root);
    }

    createBreadCrumb(
        route: ActivatedRoute,
        url: string = '',
        breadcrumbs: MenuBreadcrumb[] = [],
    ): MenuBreadcrumb[] {
        const label = route?.routeConfig?.data ? route.routeConfig.data.breadcrumbName : '';
        let path = route?.routeConfig?.data ? route.routeConfig.path : '';

        const splittedPath = path?.split('/') || [];
        const lastRoutePart = [...splittedPath].pop() || "";
        const firstRoutePart = [...splittedPath].shift() || "";
        const isDynamicRouteFirst = firstRoutePart?.startsWith(':');
        const isDynamicRouteLast = lastRoutePart?.startsWith(':');

        if ((isDynamicRouteFirst || isDynamicRouteLast) && !!route.snapshot) {
            const paramName = (isDynamicRouteFirst ? firstRoutePart : lastRoutePart)?.split(':')[1];
            const param = route.snapshot.params[paramName];

            if (isDynamicRouteFirst) {
                path = path?.replace(firstRoutePart, param);
            } else {
                path = path?.replace(lastRoutePart, param);
            }
        }

        const nextUrl = path ? `${url}/${path}` : url;

        const breadcrumb: MenuBreadcrumb = {
            label: label || '',
            url: nextUrl
        };

        const newBreadcrumbs = (breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs]).filter(item => item?.label);

        if (route.firstChild) return this.createBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);

        this.rootExtrasService.setTitle(newBreadcrumbs.map(item => item.label));

        return newBreadcrumbs;
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.actionsService
                .watchCreateAction()
                .subscribe((createAction: FormButton | Generic) => {
                    this.createAction = createAction;
                    this.changeDetector.detectChanges();
                }),
            this.router
                .events
                .pipe(filter(event => event instanceof NavigationEnd), distinctUntilChanged())
                .subscribe(() => this.mountBreadcrumbWhenDifferentRoutes())
        );
    }

    open(): void {
        this.menu?.toggle();
        this.tabsService.sendUpdatePagination(true);
    }

    get path(): Observable<string> {
        return of(this.rootExtrasService.breadcrumbName);
    }

    ngOnDestroy(): void {
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    private mountBreadcrumbWhenDifferentRoutes(): void {
        if (!this.routesService.areLastRoutesEqual()) this.service.breadcrumb = this.createBreadCrumb(this.route.root);
    }
}
