import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {TranslateModule} from "@ngx-translate/core";
import {
    AngularMaterialModule,
    DirectivesModule,
    PipesModule,
    AvatarModule,
    CopyrightModule,
    LinkModule,
    NoResultsModule,
    ProgressSpinnerModule,
    FormModule
} from "material-angular-components";

import {MenuComponent} from './menu.component';
import {MenuBreadcrumbComponent} from './breadcrumb/menu-breadcrumb.component';
import {MenuToolbarComponent} from './toolbar/menu-toolbar.component';
import {LocalActionsModule} from '../local-actions/local-actions.module';
import {FiltersModule} from '../filters/filters.module';
import {MenuOrderPipe} from "../../shared/pipes/menu-order/menu-order.pipe";

@NgModule({
    declarations: [
        MenuComponent,
        MenuBreadcrumbComponent,
        MenuToolbarComponent,
        MenuOrderPipe
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        RouterModule,
        PipesModule,
        FormModule,
        DirectivesModule,
        LocalActionsModule,
        FiltersModule,
        LinkModule,
        TranslateModule,
        AvatarModule,
        CopyrightModule,
        ProgressSpinnerModule,
        NoResultsModule
    ],
    exports: [
        MenuComponent
    ]
})
export class MenuModule {
}
