import {ErrorHandler, Injectable, Injector, Inject, InjectionToken} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";

import {fromEvent} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import Rollbar from "rollbar";
import {Auxiliary, Generic, SnackBarService} from "material-angular-components";

import {environment} from "../../../environments/environment";
import {version} from '../../../../version';

const rollbarConfig: Rollbar.Configuration = {
    accessToken: 'f6e3f35f388649a4922d0b203454e646',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: environment.local,
    enabled: environment.production || environment.sandbox || environment.demonstration,
    payload: {
        client: {
            javascript: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                source_map_enabled: true,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                code_version: version.revision,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                guess_uncaught_frames: true
            }
        }
    }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
export const rollbarFactory = () => new Rollbar(rollbarConfig);

@Injectable({
    providedIn: 'root'
})
export class ErrorsHandlerService implements ErrorHandler {
    private messages: Generic = {};
    private translateService: TranslateService = {} as TranslateService;

    constructor(
        private snackBarService: SnackBarService,
        private injector: Injector,
        @Inject(RollbarService) private rollbar: Rollbar
    ) {
        this.init();
    }

    static isKnownError(error: any): boolean {
        if (error instanceof HttpErrorResponse)
            return [0, 401, 403, 404, 422, 500, 504].includes(error?.status) ||
                error?.error instanceof Blob ||
                Auxiliary.isModuleLoadingError(error);
        else return false;
    }

    init(): void {
        setTimeout(() => {
            this.translateService = this.injector.get(TranslateService);

            this.translateService
                .get("errors")
                .subscribe(messagesError => this.messages = messagesError);

            fromEvent(window, 'offline').subscribe(() => this.errorConnection(this.messages.connectionDown));
            fromEvent(window, 'online').subscribe(() => this.snackBarService.create(this.messages.connectionComeBack, true));
        }, 2000);
    }

    handleError(error: any): void {
        if (Auxiliary.isModuleLoadingError(error)) this.errorConnection(this.messages.moduleImport);
        if (environment.development) console.error(error);
        if (!ErrorsHandlerService.isKnownError(error)) {
            const currentError = error?.originalError || error;
            const hasMessage = !!(error?.message || error?.originalError?.message);

            if (hasMessage) this.rollbar.error(currentError);
        }
    }

    private errorConnection(message: string): void {
        this.snackBarService.create(message, false);
    }
}
