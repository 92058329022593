import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";

import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Auxiliary, Translate } from "material-angular-components";

import { environment } from "../../../environments/environment";
import { User } from "../../shared/models/user";

@Injectable({
	providedIn: 'root'
})
export class PasswordResetService {
	constructor(private http: HttpClient, private router: Router) {
	}

	get origin(){
		const origin = window.location.origin;

		const withoutSlash = origin.replace(/\/$/, "");
		const withoutLocalHost = withoutSlash.replace("localhost", "127.0.0.1");

		return `${withoutLocalHost}/`;
	}

	reset(form: FormGroup): Observable<null> {
		return this.http
			.post<null>(
				`${environment.api}/auth/password`,
				{
					...form.getRawValue(),
					redirectUrl: `${window.location.origin}${Translate.value('routes.passwordEdit.path')}`
				}
			)
			.pipe(take(1));
	}

	generateCode(params: { cellphone: string }) {
		return this.http
			.post<null>(
				`${environment.api}/auth/cellphone_recovery/generate_code`,
				{
					...params,
					baseUrl: this.origin
				},
			).pipe(take(1));
	}

	validateCode(params: { cellphone: string; code: string }) {
		return this.http
			.post<{user: User}>(
				`${environment.api}/auth/cellphone_recovery/validate_code`,
				{
					...params,
					baseUrl: this.origin
				},
				{ observe: 'response' }
			).pipe(take(1));
	}

	isAtPasswordReset(): boolean {
		return Auxiliary.removeUrlParameters(this.router.url) === Translate.value('routes.passwordReset.path');
	}
}
