import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';

import {TransferHttpCacheModule} from '@nguniversal/common';
import {TranslateModule} from "@ngx-translate/core";
import {
    Translate,
    GlobalLoadingModule,
    ZoomImgModule,
    AngularMaterialModule
} from 'material-angular-components';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LocalActionsModule} from './core/local-actions/local-actions.module';
import {FiltersModule} from './core/filters/filters.module';
import {AppRequestsModule} from "./app-requests.module";
import {AuthenticationModule} from "./core/authentication/authentication.module";
import {ErrorsModule} from "./core/errors/errors.module";
import {MenuModule} from "./core/menu/menu.module";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        TranslateModule.forRoot(Translate.configurations),
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularMaterialModule,
        HttpClientModule,
        LocalActionsModule,
        FiltersModule,
        AppRequestsModule,
        AuthenticationModule,
        GlobalLoadingModule,
        ErrorsModule,
        TransferHttpCacheModule,
        ZoomImgModule,
        MenuModule
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            }
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
