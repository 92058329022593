import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {ActivatedRoute, Data, NavigationEnd, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

import {filter, map, switchMap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {Generic} from "material-angular-components";

import {AppRoutesService} from "./app-routes.service";

@Injectable({
    providedIn: 'root'
})
export class AppExtrasService {
    breadcrumbName = '';

    private language = '';
    private title = '';
    private metas: Generic = {keywords: "", author: "", copyright: "", description: ""};
    private noscript = '';
    private renderer: Renderer2;
    private separatorTitle = ' - ';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private appRoutesServ: AppRoutesService,
        private titleService: Title,
        private meta: Meta,
        private translateService: TranslateService,
        rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.translateService
            .get("root")
            .subscribe(labels => {
                this.language = labels.language;
                this.title = labels?.title;
                this.metas = labels.metas;
                this.noscript = labels.noscript;

                this.renderer.setAttribute(document.querySelector('html'), 'lang', this.language as string);
                this.renderer.setProperty(document.querySelector('noscript'), 'textContent', this.noscript);
            });
    }

    private static removeNotificationInfo(title: string): string {
        return title.replace(/\(\d+\)/g, '').trim();
    }

    watchTitle(): void {
        this.router
            .events
            .pipe(filter(event => event instanceof NavigationEnd))
            .pipe(map(() => this.activatedRoute))
            .pipe(map(route => {
                while (route.firstChild) route = route.firstChild;

                return route;
            }))
            .pipe(switchMap(route => route.data))
            .subscribe((data: Data = {}) => {
                if (this.appRoutesServ.areLastRoutesEqual()) return;

                const breadcrumbName = data?.breadcrumbName;
                const metas = data?.metas || {};

                if (breadcrumbName) this.breadcrumbName = breadcrumbName;

                Object.keys(this.metas)
                    .forEach((key: string) => this.meta.updateTag({
                        name: key,
                        content: metas?.[key] || this.metas?.[key]
                    }));
            });
    }

    setTitle(titles: string[] = []): void {
        titles = titles.filter(Boolean);

        const title = AppExtrasService.removeNotificationInfo(
            titles.length ?
                // eslint-disable-next-line max-len
                [...titles.reverse(), this.title].filter(Boolean).map(currentTitle => AppExtrasService.removeNotificationInfo(currentTitle)).join(this.separatorTitle) :
                this.title
        );

        this.titleService.setTitle(title);
    }

    changeFirstTitle(title: string): void {
        const titles = this.getCurrentTitles(false);
        const principalTitleIdx = titles.indexOf(this.title);

        titles.splice(principalTitleIdx, 1);
        titles[0] = title;

        this.setTitle(titles.reverse());
    }

    private getCurrentTitles(reversed = true): string[] {
        const titles = this.titleService.getTitle().split(this.separatorTitle);

        return reversed ? titles.reverse() : titles;
    }
}
