import {Injectable} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";

import {Translate} from "material-angular-components";

@Injectable({
    providedIn: 'root'
})
export class BaseListService {
    constructor(private activatedRoute: ActivatedRoute) {
    }

    get defaultPage(): number {
        let defaultPage = 1;

        this.activatedRoute
            .queryParams
            .subscribe((parameters: Params = {}) =>
                defaultPage = parameters[Translate.value('table.paginator.pageUrl')] || defaultPage
            );

        return defaultPage;
    }

    defaultPerPage(pageSizes = this.defaultPageSizes): number {
        let defaultPerPage = pageSizes[0];

        this.activatedRoute
            .queryParams
            .subscribe((parameters: Params = {}) => {
                const perPage = Number(parameters[Translate.value('table.paginator.perPageUrl')]);

                defaultPerPage = pageSizes.includes(perPage) ? perPage : defaultPerPage;
            });

        return defaultPerPage;
    }

    get defaultPageSizes(): number[] {
        return [25, 50, 100];
    }
}
