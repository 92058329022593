<div class="menu">
    <div class="menu__top">
        <acc-menu-toolbar #toolbar class="menu__toolbar"></acc-menu-toolbar>
    </div>

    <mat-sidenav-container class="menu__side-container" [ngStyle]="{'top': top}">
        <mat-sidenav #menu
                     [mode]="mode"
                     class="menu__bar"
                     tabindex="-1">
            <aside class="mdc-drawer menu__container">
                <div class="mdc-drawer__content" *macVar="(menuServ.list | menuOrder) as orderedList">
                    <div *ngIf="!menuServ.loading && orderedList.length; else loadingMenu">
                        <nav class="mdc-list menu__list" *ngFor="let items of orderedList">
                            <span class="menu__title">{{ items.name | translate }}</span>
                            <a mat-list-item
                               *ngFor="let item of items.list"
                               matRipple
                               class="mdc-list-item menu__option"
                               [class]="item.backgroundColor ? 'menu__option--opacity' : ''"
                               [ngStyle]="{'background-color': item.backgroundColor}"
                               [queryParamsHandling]="menuServ.chooseHandling(item.path | translate) | async"
                               [routerLink]="item.path | translate"
                               routerLinkActive="mdc-list-item--activated menu__option--selected"
                               (click)="clickItem()">
                                <div class="menu__item">
                                    <span class="mdc-list-item__ripple"></span>
                                    <mat-icon [ngStyle]="{'color': item.color}" class="mdc-list-item__graphic menu__icon" aria-hidden="true">{{ item.icon }}</mat-icon>
                                    <p [ngStyle]="{'color': item.color}" class="mdc-list-item__text menu__item-name">{{ item.name | translate }}</p>
                                </div>
                            </a>
                        </nav>
                    </div>
                    <ng-template #loadingMenu>
                        <mac-progress-spinner mode="indeterminate"
                                              [strokeWidth]="3"
                                              class="menu__loading"
                                              txt="menu.loading.text"
                                              *ngIf="menuServ.loading; else notFoundMenu"
                                              aria-hidden="true"
                                              [diameter]="30">
                        </mac-progress-spinner>
                    </ng-template>
                    <ng-template #notFoundMenu>
                        <mac-no-results label="menu.notFound.text"
                                        class="menu__not-found"
                                        icon="category">
                        </mac-no-results>
                    </ng-template>
                </div>
                <div [ngStyle]="{ 'background-image': menuBg }" class="menu__bg" *ngIf="logoClient?.src">
                    <img [src]="logoClient.src" [alt]="logoClient.alt | translate" class="menu__logo">
                </div>

                <!--                <mac-copyright class="menu__copyright" [link]="'https://portal.com.br'"></mac-copyright>-->
            </aside>
        </mat-sidenav>

        <mat-sidenav-content class="menu__content">
            <acc-filters>
                <acc-menu-breadcrumb [menu]="menu"></acc-menu-breadcrumb>
                <main data-content class="menu__components-area"
                      [ngClass]="{'menu__components-area--create-action': !!createAction.text}">
                    <router-outlet></router-outlet>
                </main>
            </acc-filters>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
