import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';

import {Observable, ObservableInput} from 'rxjs';
import {tap, map, catchError} from "rxjs/operators";
import {Auxiliary, GlobalLoadingService, SnackBarService, FormButtonService} from "material-angular-components";

import {environment} from "../../../../environments/environment";
import {ErrorsService} from "../../errors/errors.service";

@Injectable({
    providedIn: 'root'
})
export class RequestsInterceptor implements HttpInterceptor {
    constructor(
        private snackBarService: SnackBarService,
        private errorsService: ErrorsService,
        private globalLoadingService: GlobalLoadingService,
        private formButtonService: FormButtonService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = request.clone({
            headers: Auxiliary.mergeHeaders(environment.headers(), request.headers),
            params: Auxiliary.transformParamsInSnakeCase(request.params),
            body: Auxiliary.transformBodyInSnakeCase(request.body),
            url: Auxiliary.setRequestUrl(request.url, 'json')
        });

        this.formButtonService.addLoading(request);
        this.formButtonService.setLastClickedButton(null, true);

        return next.handle(request)
            .pipe(
                map((event: HttpEvent<any>) => {
                    return event;
                }),
                catchError((error: HttpErrorResponse): ObservableInput<any> => {
                    throw error;
                }),
                tap({
                    next: (response: HttpResponse<any>) => this.formButtonService.removeLoading(response),
                    error: (error: HttpErrorResponse) => {
                        this.formButtonService.removeLoading(error);
                        this.errorsService.setErrorsByResponse(error);
                    }
                })
            );
    }
}
