import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";

import {SnackBarModule} from "material-angular-components";

import {RequestsInterceptor} from "./core/interceptors/requests/requests.interceptor";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SnackBarModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestsInterceptor,
            multi: true
        }
    ]
})
export class AppRequestsModule {
}
