import {Injectable} from '@angular/core';
import {HttpResponse} from "@angular/common/http";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

import {TranslateService} from "@ngx-translate/core";
import {filter} from "rxjs/operators";
import {Translate, GlobalLoadingService} from "material-angular-components";

import {Authentication} from "./core/authentication/authentication";
import {UsersService} from "./modules/users/users.service";
import {MenuService} from "./core/menu/menu.service";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    color = '';
    backgroundColor = '';

    private wasItAlreadyLoggedIn = false;
    private notFoundPath = Translate.value('routes.notFound.path');
    private configurationsPath = Translate.value('routes.configurations.path');
    private accountsPath = Translate.value('routes.accounts.list');
    private urlsWithoutSpaces = [this.configurationsPath, this.notFoundPath];
    private urlsWithoutMenu = [this.notFoundPath];
    private urlsWithoutBreadcrumb = [this.notFoundPath];
    private canISeeMenuBar = false;

    constructor(
        private userService: UsersService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private menuService: MenuService,
        private router: Router,
        private globalLoadingService: GlobalLoadingService,
    ) {
        this.watchChangeRoutes();
    }

    initApplicationWithUser(response: HttpResponse<any>, fromSwitchUser = false) {
        Authentication.setHeaders(Authentication.createHeadersByResponse(response));
        this.userService.setUser(response.body.user, fromSwitchUser);
        this.router.navigateByUrl(this.initialUrl);
    }

    initialLoading(): void {
        if (!this.wasItAlreadyLoggedIn) {
            this.globalLoadingService.send({show: true, before: 2000});
            this.wasItAlreadyLoggedIn = true;
        }
    }

    isAtConfigurations(url = this.router.url): boolean {
        return !!url?.startsWith(this.configurationsPath);
    }

    canISeeSpaces(): boolean {
        return !this.userService.isAdmin &&
            this.urlsWithoutSpaces.every(url => !this.router.url?.startsWith(url));
    }

    watchChangeRoutes(): void {
        this.router
            .events
            .pipe(filter(evt => evt instanceof NavigationEnd))
            .subscribe(() => {
                this.canISeeMenuBar =
                    !this.userService.isAdmin &&
                    this.urlsWithoutMenu.every(url => !this.router.url?.startsWith(url));
                this.menuService.toggle.next(this.canISeeMenuBar && !this.menuService.isBiggerThanLimit());
            });
    }

    get canISeeMenu(): boolean {
        return this.canISeeMenuBar;
    }

    get canISeeBreadcrumb(): boolean {
        return this.urlsWithoutBreadcrumb.every(url => !this.router.url?.startsWith(url));
    }

    get initialUrl(): string {
        const nextPath = this.route.snapshot.queryParams[Translate.value('routes.login.nextPath')];
        const pathNormalUser = nextPath ? nextPath : Translate.value('routes.accounts.list');
        const pathAccountAdminUser = nextPath ? nextPath : Translate.value('routes.users.list');

        return this.userService.isAccountAdmin ? pathAccountAdminUser : pathNormalUser;
    }
}
