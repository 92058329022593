<mat-sidenav-container class="filters">
    <mat-sidenav #filters
                 class="filters__bar"
                 [mode]="mode"
                 position="end">
        <form autocomplete="off" class="filters__form" [formGroup]="form" (ngSubmit)="search()">
            <div class="filters__head">
                <h4 class="filters__title">{{ 'filters.title' | translate }}</h4>
                <mac-form-button icon="clear"
                                 color="primary"
                                 theme="icon"
                                 type="button"
                                 [click]="close.bind(this)"
                                 tooltip="filters.actions.close.text"
                                 text="filters.actions.close.text">
                </mac-form-button>
            </div>
            <div class="filters__body" *ngIf="fields.length">
                <div *ngFor="let field of fields">
                    <mac-form-input [label]="field.label"
                                    *ngIf="field.model === 'input'"
                                    [type]="field.type"
                                    [placeholder]="field.placeholder"
                                    [mask]="field.mask"
                                    [form]="form"
                                    [uppercase]="field.uppercase || false"
                                    [hint]="field.hint"
                                    [maxlength]="field.maxlength"
                                    [name]="field.name">
                    </mac-form-input>
                    <mac-form-datepicker [label]="field.label"
                                         [name]="field.name"
                                         [form]="form"
                                         [formatDate]="field.formatDate"
                                         [filter]="field.filter"
                                         *ngIf="field.model === 'datepicker'"
                                         [placeholder]="field.placeholder">
                    </mac-form-datepicker>
                    <mac-form-date-range [startName]="field.startName"
                                         [endName]="field.endName"
                                         [form]="form"
                                         [formatDate]="field.formatDate"
                                         [filter]="field.filter"
                                         *ngIf="isModelDateRange(field)"
                                         [label]="field.label">
                    </mac-form-date-range>
                    <mac-form-autocomplete [label]="field.label"
                                           [service]="field.service"
                                           [options]="field.options"
                                           [sendProperty]="field.sendProperty"
                                           [nameProperty]="field.nameProperty"
                                           *ngIf="field.model === 'autocomplete'"
                                           [placeholder]="field.placeholder"
                                           [form]="form"
                                           [perPage]="field.perPage"
                                           [returnName]="field.returnName"
                                           [sortProperty]="field.sortProperty"
                                           [sortDirection]="field.sortDirection"
                                           [method]="field.method"
                                           [name]="field.name">
                    </mac-form-autocomplete>
                    <mac-form-select [label]="field.label"
                                     [nothing]="field.nothing"
                                     [sendProperty]="field.sendProperty"
                                     [nameProperty]="field.nameProperty"
                                     [returnName]="field.returnName"
                                     *ngIf="field.model === 'select'"
                                     [loadBefore]="true"
                                     [perPage]="field.perPage"
                                     [service]="field.service"
                                     [options]="field.options"
                                     [multiple]="field.multiple"
                                     [method]="field.method"
                                     [placeholder]="field.placeholder"
                                     [form]="form"
                                     [name]="field.name">
                    </mac-form-select>
                </div>
            </div>
            <mac-form-actions class="filters__actions"
                              [secondaryAction]="clear"
                              [primaryAction]="save">
            </mac-form-actions>
        </form>


    </mat-sidenav>

    <mat-sidenav-content class="filters__content">
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
