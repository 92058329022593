import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {FormButton, Generic} from 'material-angular-components';

import {LocalAction} from '../../shared/models/local-action';

@Injectable({
    providedIn: 'root'
})
export class LocalActionsService {
    private actionsSubject = new BehaviorSubject<LocalAction | Generic>({});
    private createActionSubject = new BehaviorSubject<FormButton | Generic>({});

    watchActions(): Observable<LocalAction | Generic> {
        return this.actionsSubject.asObservable();
    }

    sendActions(actions: LocalAction | Generic): void {
        this.actionsSubject.next(actions);
    }

    watchCreateAction(): Observable<FormButton | Generic> {
        return this.createActionSubject.asObservable();
    }

    sendCreateAction(actions: FormButton | Generic): void {
        this.createActionSubject.next(actions);
    }
}
